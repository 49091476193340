<template>
  <el-page-header v-if="route.path !== '/'" @back="handleBack">
    <template #breadcrumb>
      <el-breadcrumb :separator-icon="ArrowRight" class="adaptive-breadcrumbs">
        <el-breadcrumb-item :to="{ path: '/' }">
          <el-icon size="16"><HomeFilled /></el-icon>
        </el-breadcrumb-item>

        <!-- Средние элементы (сворачиваются) -->
        <template v-if="!isExpanded && breadcrumbs.length > 2">
          <el-breadcrumb-item>
            <el-popover
              placement="bottom"
              :width="200"
              trigger="click"
              v-model:visible="isPopoverVisible"
            >
              <template #reference>
                <span class="cursor-pointer">...</span>
              </template>
              <div class="hidden-breadcrumbs">
                <div
                  v-for="(crumb, idx) in breadcrumbs.slice(0, -1)"
                  :key="idx"
                  class="breadcrumb-item"
                >
                  <router-link :to="crumb.path" @click="closePopover">
                    {{ crumb.title }}
                  </router-link>
                </div>
              </div>
            </el-popover>
          </el-breadcrumb-item>
        </template>

        <template v-else>
          <template
            v-for="(crumb, index) in breadcrumbs.slice(0, -1)"
            :key="index"
          >
            <el-breadcrumb-item :to="crumb.path">
              {{ crumb.title }}
            </el-breadcrumb-item>
          </template>
        </template>

        <!-- Последний элемент всегда видим -->
        <el-breadcrumb-item
          v-if="breadcrumbs.length > 0"
          :to="breadcrumbs[breadcrumbs.length - 1].path"
          class="current-page"
        >
          {{ breadcrumbs[breadcrumbs.length - 1].title }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </template>

    <!-- Остальной контент -->
    <template #content>
      <div class="flex items-center justify-between">
        <el-text size="large" tag="b" truncated>
          {{ route.meta?.title || "Страница" }}
        </el-text>
        <slot name="actions"></slot>
      </div>
    </template>
  </el-page-header>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { HomeFilled, ArrowRight } from "@element-plus/icons-vue";

const router = useRouter();
const route = useRoute();
const isExpanded = ref(false);
const isPopoverVisible = ref(false);

const closePopover = () => {
  isPopoverVisible.value = false;
};
// Отслеживание ширины экрана
const checkScreenWidth = () => {
  isExpanded.value = window.innerWidth > 768;
};

onMounted(() => {
  checkScreenWidth();
  window.addEventListener("resize", checkScreenWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", checkScreenWidth);
});

interface Breadcrumb {
  title: string;
  path: string;
  active?: boolean;
}

const breadcrumbs = computed<Breadcrumb[]>(() => {
  const { meta } = route;
  const result: Breadcrumb[] = [];

  // Добавляем "Боты" только если мы находимся в разделе ботов
  if (route.path.startsWith("/bots")) {
    result.push({
      title: "Боты",
      path: "/bots",
      active: route.path === "/bots",
    });
  }

  // Если есть функция для создания хлебных крошек
  if (meta.breadcrumb && typeof meta.breadcrumb === "function") {
    const customBreadcrumbs = meta.breadcrumb(route);
    result.push(...customBreadcrumbs);
  }
  // Если просто строка
  else if (meta.breadcrumb && typeof meta.breadcrumb === "string") {
    result.push({
      title: meta.breadcrumb,
      path: route.path,
      active: true,
    });
  }

  return result;
});

const handleBack = () => {
  router.back();
};
</script>

<style scoped>
.adaptive-breadcrumbs {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hidden-breadcrumbs {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.breadcrumb-item {
  padding: 4px 8px;
  border-radius: 4px;
}

.breadcrumb-item:hover {
  background-color: var(--el-fill-color-light);
}

.current-page {
  color: var(--el-text-color-regular);
  font-weight: 600;
}

:deep(.el-page-header__header) {
  overflow-x: auto;
}

:deep(.el-breadcrumb__item) {
  line-height: 1.4;
}
:deep(.el-page-header__breadcrumb) {
  margin-bottom: 16px;
}
.el-breadcrumb__item .el-icon {
  vertical-align: top;
}
@media (max-width: 768px) {
  .adaptive-breadcrumbs {
    font-size: 14px;
  }

  :deep(.el-breadcrumb__item) {
    margin-bottom: 4px;
  }
}
</style>
