<template>
  <!-- Показываем контент только после проверки авторизации -->
  <template v-if="authStore.getInitialized">
    <component
      :is="
        isLayoutLoading
          ? AuthLayout
          : layout === 'AuthLayout'
          ? AuthLayout
          : layout === 'BotLayout'
          ? BotLayout
          : DefaultLayout
      "
    >
      <router-view />
    </component>
  </template>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/modules/auth";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import BotLayout from "@/layouts/BotLayout.vue";

const route = useRoute();
const authStore = useAuthStore();

const layout = computed(() => {
  return route.meta.layout || "DefaultLayout";
});
const isLayoutLoading = computed(() => {
  return !authStore.isAuthenticated && !route.meta.public;
});
</script>
