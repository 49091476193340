import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "hidden-breadcrumbs" }
const _hoisted_2 = { class: "flex items-center justify-between" }

import { computed, ref, onMounted, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { HomeFilled, ArrowRight } from "@element-plus/icons-vue";

interface Breadcrumb {
  title: string;
  path: string;
  active?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PageHeader',
  setup(__props) {

const router = useRouter();
const route = useRoute();
const isExpanded = ref(false);
const isPopoverVisible = ref(false);

const closePopover = () => {
  isPopoverVisible.value = false;
};
// Отслеживание ширины экрана
const checkScreenWidth = () => {
  isExpanded.value = window.innerWidth > 768;
};

onMounted(() => {
  checkScreenWidth();
  window.addEventListener("resize", checkScreenWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", checkScreenWidth);
});

const breadcrumbs = computed<Breadcrumb[]>(() => {
  const { meta } = route;
  const result: Breadcrumb[] = [];

  // Добавляем "Боты" только если мы находимся в разделе ботов
  if (route.path.startsWith("/bots")) {
    result.push({
      title: "Боты",
      path: "/bots",
      active: route.path === "/bots",
    });
  }

  // Если есть функция для создания хлебных крошек
  if (meta.breadcrumb && typeof meta.breadcrumb === "function") {
    const customBreadcrumbs = meta.breadcrumb(route);
    result.push(...customBreadcrumbs);
  }
  // Если просто строка
  else if (meta.breadcrumb && typeof meta.breadcrumb === "string") {
    result.push({
      title: meta.breadcrumb,
      path: route.path,
      active: true,
    });
  }

  return result;
});

const handleBack = () => {
  router.back();
};

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_page_header = _resolveComponent("el-page-header")!

  return (_unref(route).path !== '/')
    ? (_openBlock(), _createBlock(_component_el_page_header, {
        key: 0,
        onBack: handleBack
      }, {
        breadcrumb: _withCtx(() => [
          _createVNode(_component_el_breadcrumb, {
            "separator-icon": _unref(ArrowRight),
            class: "adaptive-breadcrumbs"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_breadcrumb_item, { to: { path: '/' } }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, { size: "16" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(HomeFilled))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (!isExpanded.value && breadcrumbs.value.length > 2)
                ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_popover, {
                        placement: "bottom",
                        width: 200,
                        trigger: "click",
                        visible: isPopoverVisible.value,
                        "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((isPopoverVisible).value = $event))
                      }, {
                        reference: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createElementVNode("span", { class: "cursor-pointer" }, "...", -1)
                        ])),
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(breadcrumbs.value.slice(0, -1), (crumb, idx) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: idx,
                                class: "breadcrumb-item"
                              }, [
                                _createVNode(_component_router_link, {
                                  to: crumb.path,
                                  onClick: closePopover
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(crumb.title), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["to"])
                              ]))
                            }), 128))
                          ])
                        ]),
                        _: 1
                      }, 8, ["visible"])
                    ]),
                    _: 1
                  }))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(breadcrumbs.value.slice(0, -1), (crumb, index) => {
                    return (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
                      key: index,
                      to: crumb.path
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(crumb.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  }), 128)),
              (breadcrumbs.value.length > 0)
                ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
                    key: 2,
                    to: breadcrumbs.value[breadcrumbs.value.length - 1].path,
                    class: "current-page"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(breadcrumbs.value[breadcrumbs.value.length - 1].title), 1)
                    ]),
                    _: 1
                  }, 8, ["to"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["separator-icon"])
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_text, {
              size: "large",
              tag: "b",
              truncated: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(route).meta?.title || "Страница"), 1)
              ]),
              _: 1
            }),
            _renderSlot(_ctx.$slots, "actions")
          ])
        ]),
        _: 3
      }))
    : _createCommentVNode("", true)
}
}

})