<template>
  <div class="side-nav-toggle">
    <el-button link size="small" plain @click="isCollapse = !isCollapse">
      <el-icon size="18" v-if="isCollapse"><Menu /></el-icon>
      <el-icon size="18" v-else><Close /></el-icon>
    </el-button>
  </div>
  <el-menu
    class="side-nav"
    :default-openeds="['1']"
    router
    @select="handleSelect"
    :collapse="isCollapse"
    @open="handleOpen"
    @close="handleClose"
  >
    <template v-for="route in menuRoutes" :key="route.path">
      <el-menu-item
        v-if="!route.meta?.hideInMenu && !route.children"
        :index="route.path"
      >
        <el-icon v-if="route.meta?.iconComponent">
          <component :is="route.meta.iconComponent" />
        </el-icon>
        <template #title>{{ route.meta?.breadcrumb || route.name }}</template>
      </el-menu-item>

      <el-menu-item-group
        v-else-if="route.meta?.group"
        :title="route.meta.group"
      >
        <el-menu-item
          v-for="child in filterChildren(route.children)"
          :key="child.path"
          :index="child.path"
        >
          <el-icon v-if="child.meta?.iconComponent">
            <component :is="child.meta.iconComponent" />
          </el-icon>
          <template #title>{{ child.meta?.breadcrumb || child.name }}</template>
        </el-menu-item>
      </el-menu-item-group>

      <el-sub-menu v-else-if="route.children" :index="route.path">
        <template #title>
          <el-icon v-if="route.meta?.iconComponent">
            <component :is="route.meta.iconComponent" />
          </el-icon>
          <span>{{ route.meta?.breadcrumb || route.name }}</span>
        </template>

        <el-menu-item
          v-for="child in filterChildren(route.children)"
          :key="child.path"
          :index="child.path"
        >
          <el-icon v-if="child.meta?.iconComponent">
            <component :is="child.meta.iconComponent" />
          </el-icon>
          <template #title>{{ child.meta?.breadcrumb || child.name }}</template>
        </el-menu-item>
      </el-sub-menu>
    </template>
  </el-menu>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { RouteRecordRaw, useRouter } from "vue-router";
import { useAuthStore } from "@/stores/modules/auth";
import {
  HomeFilled,
  User,
  Document,
  InfoFilled,
  Brush,
  Message,
  Menu,
  Close,
} from "@element-plus/icons-vue";
import BotIcon from "../icons/BotIcon.vue";
const iconComponents = {
  HomeFilled,
  User,
  Document,
  InfoFilled,
  Brush,
  Message,
  Menu,
  Close,
  BotIcon,
};
const authStore = useAuthStore();
const router = useRouter();
const isCollapse = ref(true);
// Функция для получения компонента иконки по имени
const getIconComponent = (iconName: string) => {
  return iconComponents[iconName as keyof typeof iconComponents];
};
// Обработчик выбора пункта меню
const handleSelect = (index: string) => {
  // Дополнительная логика при необходимости
  console.log("Selected menu item:", index);
};

// Функция проверки доступа по ролям
const hasAccess = (roles?: string[]) => {
  if (!roles || roles.length === 0) return true;
  return roles.some((role: string) => authStore.userRole === role);
};

// Функция фильтрации дочерних маршрутов
const filterChildren = (children: RouteRecordRaw[] = []) => {
  return children.filter(
    (child) => !child.meta?.roles || hasAccess(child.meta.roles as string[])
  );
};

const menuRoutes = computed(() => {
  const routes = router.options.routes;

  const processRoute = (route: RouteRecordRaw) => {
    const processedRoute = { ...route };

    if (processedRoute.meta?.icon) {
      processedRoute.meta = {
        ...processedRoute.meta,
        iconComponent: getIconComponent(processedRoute.meta.icon as string),
      };
    }

    if (processedRoute.path === "/bots") {
      delete processedRoute.children;
      processedRoute.meta = {
        ...processedRoute.meta,
        hideInMenu: false,
      };
    }

    return processedRoute;
  };

  const publicRoutes = routes
    .filter((route) => {
      // Проверяем права доступа
      if (route.meta?.roles && !hasAccess(route.meta.roles as string[])) {
        return false;
      }

      // Фильтруем только нужные маршруты
      if (!route.meta?.requiresAuth || route.meta?.hideInMenu) {
        return false;
      }

      // Для вложенных маршрутов проверяем родительский путь
      if (route.path === "/bots") {
        return true;
      }

      return route.path.startsWith("/") && !route.path.includes(":");
    })
    .map(processRoute);

  return publicRoutes;
});

const handleOpen = (key: string, keyPath: string[]) => {
  console.log(key, keyPath);
};

const handleClose = (key: string, keyPath: string[]) => {
  console.log(key, keyPath);
};
</script>

<style scoped>
.side-nav {
  padding-top: var(--el-menu-item-height);
  min-height: 100vh;
  height: 100%;
}
.side-nav-toggle {
  height: var(--el-menu-item-height);
  padding: 0 var(--el-menu-base-level-padding);
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
}
.side-nav:not(.el-menu--collapse) {
  width: 200px;
  min-height: 100vh;
}
.el-menu-vertical {
  height: 100%;
  border-right: none;
}

.el-menu-vertical:not(.el-menu--collapse) {
  width: 250px;
}

:deep(.el-menu-item-group__title) {
  padding: 8px 16px;
  font-size: 12px;
  color: var(--el-text-color-secondary);
}
</style>
