import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "left-section" }
const _hoisted_4 = { class: "right-section" }
const _hoisted_5 = { class: "user-profile" }
const _hoisted_6 = { class: "user-name" }

import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/modules/auth";
import ThemeSwitch from "@/components/theme/ThemeSwitch.vue";
import { ArrowDown, User, SwitchButton } from "@element-plus/icons-vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ThemeHeader',
  setup(__props) {

const router = useRouter();
const authStore = useAuthStore();

const handleLogout = async () => {
  try {
    await authStore.logout();
    router.push("/login");
  } catch (error) {
    console.error("Logout error:", error);
  }
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: "/",
          class: "logo"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Logo",
              height: "40"
            }, null, -1)
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_unref(authStore).user)
          ? (_openBlock(), _createBlock(_component_el_dropdown, {
              key: 0,
              trigger: "click"
            }, {
              dropdown: _withCtx(() => [
                _createVNode(_component_el_dropdown_menu, null, {
                  default: _withCtx(() => [
                    (_unref(authStore).user?.role === 'admin')
                      ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                          key: 0,
                          index: "/admin"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_icon, null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(User))
                              ]),
                              _: 1
                            }),
                            _cache[1] || (_cache[1] = _createTextVNode(" Админ панель "))
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_el_dropdown_item, { icon: _unref(User) }, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, { to: "/profile" }, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode("Профиль")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["icon"]),
                    _createVNode(_component_el_dropdown_item, {
                      icon: _unref(SwitchButton),
                      divided: "",
                      onClick: handleLogout
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" Выйти ")
                      ])),
                      _: 1
                    }, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  (_unref(authStore).user.telegramPhotoUrl)
                    ? (_openBlock(), _createBlock(_component_el_avatar, {
                        key: 0,
                        size: 32,
                        src: _unref(authStore).user.telegramPhotoUrl
                      }, null, 8, ["src"]))
                    : (_openBlock(), _createBlock(_component_el_avatar, { key: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(authStore).user.name?.charAt(0)), 1)
                        ]),
                        _: 1
                      })),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(authStore).user.name), 1),
                  _createVNode(_component_el_icon, { class: "el-icon--right" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(ArrowDown))
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(ThemeSwitch)
      ])
    ])
  ]))
}
}

})