/* eslint-disable no-useless-catch */
import { defineStore } from "pinia";
import { fetchWrapper } from "@/helpers/fetch-wrapper";
import router from "@/router";
import { API_URL } from "@/constants/api";
import { ElLoading } from "element-plus";

interface User {
  id: number;
  email?: string;
  username: string;
  name?: string;
  role: "user" | "manager" | "admin";
  password?: string;
  telegramId?: number;
  telegramPhotoUrl?: string;
}

interface AuthState {
  user: User | null;
  accessToken: string | null;
  returnUrl: string | null;
  isInitialized: boolean;
  telegramAuthCode: string | null; // добавляем новое поле
}

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    user: JSON.parse(localStorage.getItem("user") || "null"),
    accessToken: localStorage.getItem("token"),
    returnUrl: null,
    isInitialized: false,
    telegramAuthCode: null,
  }),

  getters: {
    isAuthenticated: (state) => !!state.user,
    userRole: (state) => state.user?.role,
    getInitialized: (state) => state.isInitialized,
  },

  actions: {
    async init() {
      // Создаем экземпляр loading
      const theme = localStorage.getItem("theme");
      const loading = ElLoading.service({
        lock: true,
        text: "Проверка авторизации...",
        background:
          theme === "dark" ? "var(--dark-theme)" : "var(--light-theme)",
      });

      try {
        const token = localStorage.getItem("token");
        const user = JSON.parse(localStorage.getItem("user") || "null");

        if (token && user) {
          try {
            const response = await fetchWrapper.get(`${API_URL}auth/validate`);

            if (response.data?.valid) {
              this.user = response.data.user;
              this.accessToken = token;
              localStorage.setItem("user", JSON.stringify(response.data.user));
            } else {
              this.clearAuth();
            }
          } catch (error: unknown) {
            this.clearAuth();
            if ((error as { status: number }).status !== 401) {
              console.error("Token validation error:", error);
            }
          }
        } else {
          this.clearAuth();
        }
      } finally {
        this.isInitialized = true;
        // Закрываем loading
        loading.close();
      }
    },

    async initiateTelegramAuth() {
      const response = await fetchWrapper.get(`${API_URL}auth/telegram/init`);
      if (response.data?.authUrl) {
        const code = response.data.authUrl.split("auth_")[1];
        this.telegramAuthCode = code;
        localStorage.setItem("telegramAuthCode", code);
        return response.data.authUrl;
      }
      throw new Error("Не удалось получить ссылку для авторизации");
    },

    async verifyTelegramAuth() {
      const code =
        this.telegramAuthCode || localStorage.getItem("telegramAuthCode");

      if (!code) {
        throw new Error("Отсутствует код авторизации");
      }

      const response = await fetchWrapper.get(
        `${API_URL}auth/telegram/verify?code=${code}`
      );
      console.log("response", response);
      if (response.data?.token) {
        // Обновляем состояние
        const { token, ...user } = response.data;
        this.user = user;
        this.accessToken = token.token;

        // Сохраняем в localStorage
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token.token);

        // Очищаем код только после успешной авторизации
        this.telegramAuthCode = null;
        localStorage.removeItem("telegramAuthCode");

        // Редирект на предыдущую страницу или домой
        router.push(this.returnUrl || "/");
        return true;
      }

      if (response.data?.message === "Authorization pending") {
        return false; // Продолжаем polling
      } else {
        throw new Error(
          response.data?.message || "Ошибка проверки авторизации"
        );
      }
    },

    async login(username: string, password: string) {
      const response = await fetchWrapper.post(`${API_URL}auth/login`, {
        username,
        password,
      });

      // Обновляем состояние
      const { token, ...user } = response.data;
      this.user = user;
      this.accessToken = token?.token;

      // Сохраняем в localStorage
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token?.token);

      // Редирект на предыдущую страницу или домой
      router.push(this.returnUrl || "/");
    },

    async logout(silent = false) {
      // Если silent = true, не делаем запрос на сервер
      if (!silent) {
        try {
          await fetchWrapper.post(`${API_URL}auth/logout`);
        } catch (error) {
          console.error("Logout error:", error);
        }
      }

      // Очищаем состояние
      this.clearAuth();

      // Редирект на страницу логина
      router.push("/login");
    },

    clearAuth() {
      this.user = null;
      this.accessToken = null;
      this.returnUrl = null;
      this.telegramAuthCode = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("telegramAuthCode");
    },

    async register(username: string, password: string) {
      const response = await fetchWrapper.post(`${API_URL}auth/register`, {
        username,
        password,
      });

      // Проверяем успешность регистрации
      if (!response.data || response.data.error) {
        throw new Error(response.data?.error || "Ошибка при регистрации");
      }

      return response;
    },

    async checkTelegramLink() {
      const response = await fetchWrapper.post(
        `${API_URL}auth/telegram-binding`
      );

      return response;
    },

    async me() {
      const response = await fetchWrapper.get(`${API_URL}auth/me`);
      if (response.data?.id) {
        this.user = response.data;
        localStorage.setItem("user", JSON.stringify(response.data));
      } else {
        throw new Error("Не удалось получить данные пользователя");
      }
    },
  },
});
