/**
 * Очищает текст от HTML тегов и лишних пробелов
 */
const cleanText = (text: string): string => {
  return text
    .replace(/<\/?[^>]+(>|$)/g, " ") // Удаляем HTML теги
    .replace(/&nbsp;/g, " ") // Заменяем неразрывные пробелы
    .replace(/\s+/g, " ") // Заменяем множественные пробелы на один
    .trim(); // Убираем пробелы в начале и конце
};

/**
 * Обрезает текст до указанной длины по словам с добавлением многоточия
 */
const truncateText = (text: string, maxLength = 160): string => {
  if (text.length <= maxLength) return text;

  // Обрезаем до maxLength и добавляем небольшой запас для поиска конца слова
  const truncated = text.slice(0, maxLength + 20);
  // Ищем последний пробел перед maxLength
  const lastSpace = truncated.lastIndexOf(" ", maxLength);

  // Если пробел найден, обрезаем по нему, иначе по maxLength
  return lastSpace > 0
    ? truncated.slice(0, lastSpace)
    : truncated.slice(0, maxLength);
};

export const setMetaData = (title?: string, description?: string) => {
  // Установка title
  if (title) {
    document.title = cleanText(title);
  }

  // Установка description
  if (description) {
    const cleanDescription = truncateText(cleanText(description));
    const metaDescription = document.querySelector('meta[name="description"]');

    if (metaDescription) {
      metaDescription.setAttribute("content", cleanDescription);
    } else {
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content = cleanDescription;
      document.head.appendChild(meta);
    }
  }
};
