export const ROUTE_NAMES = {
  THEME: "theme",
  HOME: "home",
  LOGIN: "login",
  SIGNUP: "signup",
  BOTS: "bots",
  BROADCAST_BOT: "broadcast-bot",
  BOT: "bot",
  BOT_ITEM: "bot-item",
  CONTENTS: "contents",
  BROADCAST_CONTENT: "broadcast-content",
  SETTINGS: "settings",
  MESSAGES: "messages",
  FILES: "files",
  UNAUTHORIZED: "unauthorized",
  USERS: "users",
  PROFILE: "profile",
  NOT_FOUND: "not-found",
  ABOUT: "about",
  CONTENT_ITEM: "content-item",
  MEDIA: "media",
  FUNNELS: "funnels",
  COMMANDS: "commands",
  FUNNEL_EDITOR: "funnel-editor",
  INSTRUCTIONS: "instructions",
  TASK_ITEM: "task-item",
  TASK_LIST: "task-list",
  SUBSCRIBERS: "subscribers",
  FUNNEL_STATS: "funnel-stats",
} as const;
