import { createApp } from "vue";
import { createPinia } from "pinia";
import ElementPlus from "element-plus";
import App from "@/App.vue";
import "@/registerServiceWorker";
import router from "@/router";
import { role } from "@/directives";
import "element-plus/theme-chalk/el-reset.css";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/dark/css-vars.css";
import "@/assets/styles/main.styl";
import "@/assets/styles/theme-vars.styl";
import ru from "element-plus/dist/locale/ru.mjs";

const app = createApp(App);
const pinia = createPinia();
app.use(ElementPlus, {
  locale: ru, // Русская локализация
  size: "default", // Размер компонентов по умолчанию
  zIndex: 3000, // z-index для модальных окон
});
app.directive("role", role);
app.use(pinia).use(router).mount("#app");
