import { createRouter, createWebHistory } from "vue-router";
import type { RouteRecordRaw } from "vue-router";
import { authGuard } from "./guards/auth.guard";
import { authRoutes } from "./modules/auth";
import { botRoutes } from "./modules/bot";
import { adminRoutes } from "./modules/admin";
import { ROUTE_NAMES } from "../constants/routes";
import { lazyLoad } from "./utils/lazyLoad";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: ROUTE_NAMES.HOME,
    component: lazyLoad("HomeView"),
    meta: {
      requiresAuth: true,
      title: "Главная",
      breadcrumb: "Главная",
      icon: "HomeFilled",
      hideInMenu: false,
    },
  },
  {
    path: "/about",
    name: ROUTE_NAMES.ABOUT,
    component: lazyLoad("AboutView"),
    meta: {
      requiresAuth: true,
      title: "О нас",
      breadcrumb: "О нас",
      icon: "InfoFilled",
      hideInMenu: false,
    },
  },
  {
    path: "/profile",
    name: ROUTE_NAMES.PROFILE,
    component: lazyLoad("Profile"),
    meta: {
      requiresAuth: true,
      title: "Профиль",
      breadcrumb: "Профиль",
      icon: "User",
      hideInMenu: false,
    },
  },
  {
    path: "/instructions",
    name: "Instructions",
    component: lazyLoad("PageInstructions"),
    beforeEnter: (to, from, next) => {
      // Предзагрузка компонентов в зависимости от устройства
      const isMobile = window.innerWidth < 768;
      const component = isMobile ? "MobileInstructions" : "DesktopInstructions";

      import(`@/components/instructions/${component}.vue`);
      next();
    },
    meta: {
      requiresAuth: true,
      title: "Создание и настройка Telegram бота",
      breadcrumb: "Инструкция",
      icon: "Document",
      hideInMenu: false,
    },
  },
  ...authRoutes,
  ...botRoutes,
  ...adminRoutes,
  {
    path: "/theme",
    name: ROUTE_NAMES.THEME,
    component: lazyLoad("PageElements"),
    meta: {
      requiresAuth: true,
      title: "Тема",
      breadcrumb: "Тема",
      icon: "Brush",
      hideInMenu: false,
      roles: ["admin"],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: ROUTE_NAMES.NOT_FOUND,
    component: lazyLoad("NotFound"),
    meta: { public: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(authGuard);

// Обработка ошибок маршрутизации
router.onError((error) => {
  console.error("Router error:", error);
  router.push({ name: ROUTE_NAMES.NOT_FOUND });
});

export default router;
