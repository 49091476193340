<template>
  <div class="header">
    <div class="header-container">
      <!-- Левая часть с логотипом и навигацией -->
      <div class="left-section">
        <router-link to="/" class="logo">
          <img src="@/assets/logo.png" alt="Logo" height="40" />
        </router-link>
      </div>

      <!-- Правая часть с профилем пользователя -->
      <div class="right-section">
        <el-dropdown v-if="authStore.user" trigger="click">
          <div class="user-profile">
            <el-avatar
              v-if="authStore.user.telegramPhotoUrl"
              :size="32"
              :src="authStore.user.telegramPhotoUrl"
            />
            <el-avatar v-else>
              {{ authStore.user.name?.charAt(0) }}
            </el-avatar>
            <span class="user-name">{{ authStore.user.name }}</span>
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </div>

          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-if="authStore.user?.role === 'admin'"
                index="/admin"
              >
                <el-icon><user /></el-icon>
                Админ панель
              </el-dropdown-item>
              <el-dropdown-item :icon="User">
                <router-link to="/profile">Профиль</router-link>
              </el-dropdown-item>
              <el-dropdown-item
                :icon="SwitchButton"
                divided
                @click="handleLogout"
              >
                Выйти
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <ThemeSwitch />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/modules/auth";
import ThemeSwitch from "@/components/theme/ThemeSwitch.vue";
import { ArrowDown, User, SwitchButton } from "@element-plus/icons-vue";

const router = useRouter();
const authStore = useAuthStore();

const handleLogout = async () => {
  try {
    await authStore.logout();
    router.push("/login");
  } catch (error) {
    console.error("Logout error:", error);
  }
};
</script>

<style>
.header {
  border-bottom: 1px solid var(--el-menu-border-color);
  margin: var(--el-main-margin);
}

.header-container {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  flex-wrap: wrap;
  margin: 0 auto;
}

.left-section {
  display: flex;
  align-items: center;
  gap: 20px;
}

.logo {
  display: flex;
  align-items: center;
}

.nav-menu {
  border-bottom: none;
}

.right-section {
  display: flex;
  align-items: center;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.user-name {
  font-size: 14px;
}
</style>
