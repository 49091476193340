<script setup lang="ts"></script>

<template>
  <div class="auth-layout">
    <el-container>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<style scoped>
.auth-layout {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(https://cp.smmai.xyz/img/image_fon.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
