import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { useAuthStore } from "@/stores/modules/auth";
import { ROUTE_NAMES } from "@/constants/routes";
import { setMetaData } from "@/utils/meta";

export async function authGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  if (to.meta.title) {
    setMetaData(
      typeof to.meta.title === "function"
        ? to.meta.title(to)
        : (to.meta.title as string) || "SMM для телеграм",
      (to.meta.description as string) ||
        "Хранение фалов, рассылка контента и создания сценариев"
    );
  }
  const auth = useAuthStore();
  if (!auth.getInitialized) {
    await auth.init();
  }

  if (!to.meta.public && !auth.isAuthenticated) {
    auth.returnUrl = to.fullPath;
    return next("/login");
  }

  if (to.meta.roles && auth.user) {
    const hasRequiredRole = (to.meta.roles as string[]).includes(
      auth.user.role
    );
    if (!hasRequiredRole) {
      return next({ name: ROUTE_NAMES.UNAUTHORIZED });
    }
  }

  if (auth.isAuthenticated && to.path === "/login") {
    return next("/");
  }

  next();
}
